import React,{useState} from 'react' ; 
import companyLogo from '../images/logo.svg'; 
import { useNavigate } from "react-router-dom"; 

 

const Form = ({onSubmit}) => {
  const[email,setEmail]=useState(""); 
	const[passw,setPassw]=useState("");
  const navigate = useNavigate();
    const handleSubmit = e => {
        e.preventDefault();
        console.log(email);
        console.log(passw);
        navigate('/statistics');
    };
    return (
      <div id='main' className='loginform'>
      <div className='d-flex align-items-center justify-content-center h-100'>
        <div className='card'>
          <div className='card-body'>
              <form onSubmit={handleSubmit}>
              <div className='text-center'>
                <img src={companyLogo} className='logo' alt="BigCo Inc. logo"/>
              </div>
              <div className="form-group mt-4">
                <label className='form-label'>Email</label>
                <input type="text" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)} className='form-control'/> 

              </div>
              <div className="input-container">
                <label className='form-label'>Password </label>
                <input type="password"  value={passw} onChange={(e)=>setPassw(e.target.value)} className="form-control" />
              </div>
              <div className="mt-4">
                <button type='submit' className='btn  btn-primary'>Login</button>
              </div>
              </form>
              
          </div>
        </div>
      </div>
    </div>
    );
};

// Usage example:

const Login = () => {
    const handleSubmit = data => {
        const json = JSON.stringify(data, null, 4);
        console.clear();
        console.log(json);
    };
    return (
      <div>
        <Form onSubmit={handleSubmit} />
      </div>
    );
};

export default Login;