import React, { Component } from 'react';
import { BrowserRouter ,Routes, Route, Link } from 'react-router-dom';
import Login from './component/login'; 
import Statistics from './component/statistics';

import './style.css';
  
class App extends Component {
  render() {
    return (
       <BrowserRouter> 
           <Routes>
                 <Route path='/' element={< Login />}></Route> 
                 <Route path='/statistics' element={< Statistics />}></Route>
          </Routes> 
       </BrowserRouter>
   );
  }
}
  
export default App;