import React, { Component } from 'react';
import { Link } from "react-router-dom";
import companyLogo from '../images/logo.svg';
import Facebook from '../images/ic-facebook.svg';
import Twitter from '../images/ic-twitter.svg';
import propertyicon from '../images/property.png';
import logout from '../images/logout.png';
import usericon from '../images/usericon.png';
import usergroupicon from '../images/usergroupicon.png';
import back from '../images/back.png';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const data = {
  labels: ['12-01 PM', '01-02 PM', '02-03 PM', '03-04 PM', '04-05 PM', '05-06 PM', '06-07 PM', '07-08 PM', '08-09 PM', '09-10 PM', '10-11 PM', '11-12 PM', '12-01 AM', '01-02 AM', '02-03 AM', '03-04 AM', '04-05 AM', '05-06 AM', '06-07 AM', '07-08 AM', '08-09 AM', '09-10 AM', '10-11 AM', '11-12 AM',],
  datasets: [
    {
      label: '',
      data: [324, 214, 57, 34, 134, 222, 342,324, 214, 57, 34, 134, 222, 342,324, 214, 57, 34, 134, 222, 342,11,409,77],
      backgroundColor: 'rgba(127, 206, 205, 0.8)',
    },
  ]
}
const dataweek = {
  labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  datasets: [
    {
      label: '',
      data: [3077, 2214, 8657, 6134, 4134, 5657, 7657,],
      backgroundColor: 'rgba(234, 76, 137, 0.8)',
    },
  ]
}
const options = {

}

 

const Statistic = () => {
  return (
    <div id='main' className='dashboard'>
      <div className='d-flex'>
        <div className='leftsidebar'>
          <img src={companyLogo} alt='logo' className='logo' />
          <ul>
            <li><Link to="/statistics" className='active'> <img src={propertyicon} alt='icon' /> Property Statistics</Link></li>
            <li><Link to='/'> <img src={logout} alt='icon' /> Sign Out</Link></li>
          </ul>
        </div>
        <div className='maincontent'>
          <div className='card m-2'>
            <div className='card-body'>
              <h3 className='mainTitle d-flex'>  Property Statistics </h3>

              <div className=''>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='mb-4'>Today Visitor</h4>
                        <div className='d-flex align-items-center'>
                          <div className='iconbox'>
                            <img src={usericon} alt='' />
                          </div>
                          <h2 className='ml-auto'>1008</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='mb-4'>Total Visitor</h4>
                        <div className='d-flex align-items-center'>
                          <div className='iconbox'>
                            <img src={usergroupicon} alt='' />
                          </div>
                          <h2 className='ml-auto'>987654345</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-4'>
                  <div className='col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='mb-4'>  Today Statistics</h4>
                        <Bar options={options} data={data} />
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='card'>
                      <div className='card-body'>
                        <h4 className='mb-4'>  This Week Statistics</h4>
                        <Bar options={options} data={dataweek} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <footer>
            <div className="copyright">© 2023 Copyright Voyak - All Rights Reserved. <span>
              <Link to="/TermsOfUse">Terms of Use</Link> | <a href="/PrivacyPolicy">Privacy Policy</a>
            </span>
            </div>
            <div className='emailfooter'>
              <Link to="mailto:support@voyak.com.au">support@voyak.com.au</Link> | <Link to="tel:07 3726 5090">07 3726 5090</Link>
            </div>
            <div className="socialicon">
              <a target='_blank' href="https://www.facebook.com/voyakhq">
                <img alt="" src={Facebook} />
              </a>
              <a target='_blank' href="https://www.twitter.com/voyakhq">
                <img alt="" src={Twitter} />
              </a>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

const Statistics = () => {
 
  return (
    <div>
      <Statistic />
    </div>
  );
};

 

export default Statistics;